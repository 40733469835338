@media only screen and (max-width: 992px) {

	ion-grid {
		padding: 0;
	}

	.mbsc-error-message-box {
		top: 59px;
    	width: 100%;
    	text-align: center;
		border-bottom-right-radius: 8px;
		border-top-right-radius: 0;
		position: initial;
	}

	.mbsc-ios.mbsc-textfield-box.mbsc-error, .mbsc-ios.mbsc-textfield-outline.mbsc-error {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		}

	.mbsc-ios.mbsc-error-message-box, .mbsc-ios.mbsc-error-message-outline {
		margin: 0 0px;
	}
	

	ion-content.background-sm-lightgrey {
		--ion-background-color: #f2f2f7;
	}

	.nk-block .heading h3 {
		margin: 20px 0 20px 0;
		font-size: 28px;
	}

	#add-kursant label {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	/* .form-primary-heading {
		font-size: 26px;
		margin-bottom: 8px !important;
		text-align: center;
		padding: 7px 20px;
		background: var(--ion-color-primary);
		color: white;
		border-radius: 24px;
		box-shadow: rgb(50 50 93 / 45%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
		letter-spacing: 0.2px;
		margin-left: auto;
		margin-right: auto;
		width: fit-content;
		display: block;
	} */

	.form-primary-heading {
		text-align: center;
		font-size: 25px;
		padding: 7px 6px;
		color: white;
		border-radius: 31px;
		letter-spacing: 0.2px;
		display: block;
	}

	.container {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.mobile-r-p {
		padding: 0 !important;
	}
	
	.form-primary {
		border: 3px solid var(--background-secondary);
		margin-top: 0;
		padding: 17px 10px;
		margin-bottom: 5px;
		background: var(--background-secondary);
	}


	/* .mbsc-scroller-wheel.mbsc-ios.mbsc-ltr {
		min-height: 60vh;
	} */

	.mbsc-optional-message-box {
		right: 0px !important;
	}

	.main-page-button-container {
		padding-top: 30px 17px;
	}

	.loader-container-secondary {
		position: absolute;
		top: 0;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, 0%);
	}
/* 
	.container-margin-header-responsive {
		margin-top: 80px;
	} */

	/* ion-header.header-collapse-fade ion-toolbar {
		padding: 2px 10px 3px 10px !important;
	} */

	/* ion-header.header-collapse-fade ion-title {
		padding-bottom: 8px !important;
	} */

	ion-header.header-collapse-condense ion-title {
		line-height: initial;
    	font-family: 'Nunito';
	}

	.add-job-container .pxp-dashboard-content-details {
		border-radius: 0;
		padding-top: 0;
	}

	ion-grid {
		margin-top: 15px;
	}

	.jobster .pxp-dashboard-content-details {
		background: none;
	}

	.find-jobs-button {
		width: 100%;
	}

	.scaled {
		will-change: transform;
		transition: transform 85ms ease-in-out;
	}
	
	.ion-activated .scaled {
		transform: scale(0.98);
	}


	.main-section {
		padding: 0.75rem!important;
		padding-top: 125px !important;
	}

	.segment-single {
		display: none;
	}

	/* ion-toolbar {
		--min-height: 50px;
	}

	ion-header ion-toolbar {
		backdrop-filter: blur(6px);
    	--background: rgba(255, 255, 255, 0.8);
	} */

	ion-header ion-toolbar {
		--min-height: 52px;
	}

	ion-toolbar {
		--background: white;
	}

	.popoverCart {
		--max-height: 400px;
	}

	ion-modal.modal1 {
		padding-top: 60px;
	}

	ion-modal.modal1::part(content) {
		border-top-left-radius: 13px;
    	border-top-right-radius: 13px;
	}

}
