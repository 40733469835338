.activateOnScroll {
    opacity: 0;
    transition: .4s opacity, .8s transform cubic-bezier(.07, .89, .79, .95), .8s;
    transform: translateY(48px);
}

@for $i from 0 through 50 {
    .td-#{$i} {
        transition-delay: ($i * 100) + ms;
    }
}


.shadow-mui {
    box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.16);
}

.shadow-border-mui {
    border: 1px solid rgb(231, 231, 231) !important;
    box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.16);
}


button:focus {
    outline: none !important
}


.mapboxgl-ctrl-logo {
    display: none !important;
}

.MuiDataGrid-columnHeaders~div:nth-of-type(3) {
    display: none;
}

.graph-button {
    margin-right: 4px;
    font-size: 14px;
}


.double-button-first {
    margin-right: 0;
    --border-radius: 0;
    --box-shadow: none;
}

.double-button-first::part(native) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.double-button-second {
    margin-left: 0;
    --border-radius: 0;
    --box-shadow: none;
}

.double-button-second::part(native) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

ion-breadcrumbs ion-breadcrumb.breadcrumb-active {
    font-weight: 700;
    color: rgb(82, 96, 255);
}

ion-modal.modal-image {
    --height: auto;
    --width: auto;
}

ion-modal.modal-image ion-img {
    height: 90vh;
}

canvas {
    padding-right: 15px;
}

.navigation-bar {
    position: sticky;
    top: 0;
    z-index: 3;
    padding-top: 10px;
    background: white;
}

ion-modal.modal-default:not(.overlay-hidden)~ion-modal.modal-default {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
    --box-shadow: 0 28px 48px rgb(0, 0, 0, 0.4) !important;
}


.margin-auto {
    margin: auto;
}

.nk-tb-item:not(.nk-tb-head):hover,
.nk-tb-item:not(.nk-tb-head).seleted {
    background: rgb(229, 236, 255) !important;
    box-shadow: 0 0 10px -4px rgba(68, 101, 191, 0.97) !important;
}


// .MuiDataGrid-main .MuiDataGrid-cell, .MuiDataGrid-main .MuiDataGrid-columnHeaderTitle {
//     font-size: 14px;
// }

.MuiDataGrid-main .MuiDataGrid-row {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.MuiDataGrid-main .MuiDataGrid-row:hover {
    border-top: 1px solid #1976d2;
    border-bottom: 1px solid #1976d2;
}


.MuiDataGrid-main .MuiDataGrid-columnHeader:focus, .MuiDataGrid-main .MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-main .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-main .MuiDataGrid-cell:focus-within {
    outline: none !important;
}


.MuiDataGrid-main .MuiDataGrid-cell {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}




// @media (max-width: 1800px), (max-height: 1040px) {
//     .payment-modal {
//         --height: 100% !important;
//         --width: 100% !important;
//     }

//     .payment-modal .modal-body {
//         overflow: scroll;
//         height: calc( 100vh - 40px );
//     }
// }

.payment-modal {
    --height: min(1100px, 90vh);
    --width: min(1800px, 90vw);
    --overflow: auto;
}

.payment-modal .close-container {
    position: sticky;
    top: 0px;
    background: white;
    --background: white;
    z-index: 1;
}