#driver-users-page-controller {
    

    .nk-tb-item:not(.nk-tb-head):hover, .nk-tb-item:not(.nk-tb-head).seleted {
        background: rgb(229, 236, 255);
        box-shadow: 0 0 10px -4px rgba(68, 101, 191, 0.97);
    }


}

