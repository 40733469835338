#payments-page-controller {
    
    .MuiDataGrid-columnHeaders ~ div:nth-of-type(4) {
        display: none;
    }

    .card-bordered {
        border-bottom: 0 !important;
    }

}

.payment-modal .MuiTableContainer-root {
    border: 1px solid rgb(212, 212, 212);
    box-shadow: rgba(0, 0, 0, 0.17) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.17) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}


.payment-modal table td, .payment-modal table th {
    font-size: 17px !important;
}

.background-color-FFD4D4 {
    background-color: #FFD4D4;
}

.background-color-FFD4D4:hover {
    background-color: #FFB6B6 !important;
}

.background-color-FFF1B3 {
    background-color: #FFF1B3;
}

.background-color-FFF1B3:hover {
    background-color: #F8E48A !important;
}


