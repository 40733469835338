#csv-modal-button {

    position: absolute;
    right: 100px;
    bottom: 0;
    width: 750px;
    pointer-events: all;

}

#csv-modal::part(content) {

    position: absolute;
    right: 100px;
    bottom: 0;
    width: 750px;
    pointer-events: all;
    height: 700px;

}

#csv-modal {
    pointer-events: none;

    .csv-header {
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid rgb(0, 0, 0);
        margin-right: 15px;
    }

}

#csv-modal::part(backdrop) {
    pointer-events: none;
}