body.dark {

    table {
        background-color: rgb(34, 36, 40);
    }

    .mapboxgl-popup-content {
        background: #141c26 !important;
    }

    .mapboxgl-popup-tip {
        border-top-color: #141c26 !important;
    }

    .csv-header {
        color: white !important;
    }

    .drivers-list-popover .item-has-start-slot {
        color: white !important;
    }

    .drivers-list-popover ion-icon {
        color: white !important;
    }

    label {
        color: white;
    }

    .navigation-bar {
        background: #121212;
    }

    table {
        background-color: rgb(34, 36, 40);
    }

    .nk-tb-item:not(.nk-tb-head):hover,
    .nk-tb-item:not(.nk-tb-head).seleted {
        background: rgb(84, 84, 84) !important;
        box-shadow: 0 0 10px -4px rgba(68, 101, 191, 0.97) !important;
    }






}