
.month {
    



    .day{
        
        font-size: 24px;
        font-weight: 550;
        padding-bottom: 5px;
    }
    .date{
        opacity: 0.5; 
    }
    .diet-item{    
        letter-spacing: 1.5px;
    }
    .list-header{
        
        text-align: center;
        letter-spacing: 1.5px;
        border: none;
    }
    
    .delivery-time-span{
        font-size: 35px;
        text-align: center;
        
        
    }
    .diet-number{
        
        font-size: 12px;
    }
    
    
    
    
    }
.navigation-bar {
    position: sticky;
    top: 0;
    z-index: 3;
    padding-top: 10px;
    background: rgb(255, 255, 255);
    
}