#drivers-page-controller {


    // .nk-tb-item:not(.nk-tb-head):hover, .nk-tb-item:not(.nk-tb-head).seleted {
    //     background: rgb(229, 236, 255);
    //     box-shadow: 0 0 10px -4px rgba(68, 101, 191, 0.97);
    // }



}

.map-container {
    width: 100%;
}

ul#map-list {
    margin-top: 10px;
    margin-bottom: 25px;
}

ul#map-list li {
    font-size: 20px;
}

.map-container .mapboxgl-popup-content div {
    text-transform: capitalize;
}