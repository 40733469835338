#reports-page-controller {

  .filters-row ion-col {
    margin-top: 5px;
  }


  .janek-shadow {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    border-radius: 9px;
    border: 1px solid rgba(224, 224, 224, 1);
  }

  .day-item {
    border-radius: 10px;
    // width: 95%;
    // left: 50%;
    // transform: translate(-50%);
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;

    //   box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid lightgrey;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .MuiDataGrid-main .MuiDataGrid-row .MuiIconButton-root.show-on-hover {
    opacity: 0;
    pointer-events: none;
  }

  .MuiDataGrid-main .MuiDataGrid-row:hover .MuiIconButton-root.show-on-hover {
    opacity: 1;
    pointer-events: all;
  }


  @media (min-width: 1920px) {
    .MuiContainer-root {
      max-width: 1920px !important;
    }
  }

}