#photos-page-controller {
    

   


}

.photo:hover .download-button{
    opacity: 1;
}

.download-button{
    position: absolute;
    font-weight: 700;
    bottom: 3px;
    left: 10px;
    width: 50px;
    height: 50px ;
    opacity: 0;
    transition: 150ms ease-in-out all ;
}
.download-icon{
    transform: scale(2);
}

.photo:hover .driver-info-photo{
    opacity: 1;
}

.driver-info-photo{
    position: absolute;
    font-weight: 700;
    top: 0px;
    left: 0;
    color: rgb(255, 255, 255);
    background: rgb(101, 118, 255);
    padding: 3px 12px 3px 11px;
    border-bottom-right-radius: 14px;
    opacity: 0;
    transition: 150ms ease-in-out all ;
}