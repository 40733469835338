.spinner-border.spinner-border-sm {
    margin-right: auto;
    margin-left: auto;
}
  
.spinner-border {
    display: none !important;
}
  
.btn-loading .spinner-border {
    display: block !important;
}

.btn-loading .spinner-border + span {
    opacity: 0 !important;
}

.btn-loading i {
    opacity: 0 !important;
}
  
.btn-loading .spinner-border {
    position: absolute;
}

.btn-lg.btn-loading .spinner-border {
    height: 35px;
    width: 35px;
}