#drivers-page-controller {


    // .nk-tb-item:not(.nk-tb-head):hover, .nk-tb-item:not(.nk-tb-head).seleted {
    //     background: rgb(229, 236, 255);
    //     box-shadow: 0 0 10px -4px rgba(68, 101, 191, 0.97);
    // }

    .drivers-grid-avatar {
        height: 37px;
        width: 37px;
        font-size: 15px;
        padding-top: 2px;
        border-width: 3px;
        border-style: solid;
        letter-spacing: 0;
        margin-right: 10px;
        font-weight: 500;
    }

}