.geocode {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
}

.geocode .f {
    font-weight: 700;
}

.geocode:hover {
    background: rgb(229 236 255);
    box-shadow: 0 0 10px -4px rgb(68 101 191 / 97%);
}


.map-modal-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(0 0 0 / 15%);
    backdrop-filter: blur(1px);
    top: 0;
    z-index: 2;
}

.popup-hover {
    font: 25px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}