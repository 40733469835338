.navbar:not(.navbar-dark) .navbar-nav .nav-item:focus > .nav-link,
.navbar:not(.navbar-dark) .navbar-nav .nav-item:hover > .nav-link {
  color: var(--ion-color-secondary);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--ion-color-secondary);
}

.star-rating {
  font-family: "Font Awesome 5 Pro";
}

.shadow-basic {
  box-shadow: var(--ion-shadow-primary);
  border: var(--ion-shadow-border-primary);
}

.shadow-basic-2 {
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  border: var(--ion-shadow-border-primary);
}

.border-transparent {
  border: 1px solid transparent;
}

.border-active-2 {
  border: 2px solid var(--ion-color-primary);
}

.border-transparent-2 {
  border: 2px solid transparent;
}

.text-gradient-custom {
  background: var(--ion-color-primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shape {
  color: #fbfbfb !important;
}

/* .ion-page {
    background: #fbfbfb;
} */

ion-accordion span svg {
  display: none;
}

ion-accordion.accordion-collapsed span svg:last-child,
ion-accordion.accordion-collapsing span svg:last-child {
  display: block;
}

ion-accordion.accordion-expanded span svg:first-child,
ion-accordion.accordion-expanding span svg:first-child {
  display: block;
}

.ion-page {
  will-change: transform, opacity;
}

ion-img.rounded-circle::part(image) {
  border-radius: 50% !important;
}

ion-img.avatar-img::part(image) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
  display: inline-flex;
}

ion-img::part(image) {
  vertical-align: middle;
  display: inline;
}

.swiper-lazy:not(.swiper-lazy-loaded) {
  visibility: hidden;
}

footer button {
  text-align: left;
}

footer .shape.shape-blur {
  margin-top: -1px;
}

.cart-collaterals .h2,
.cart-collaterals h2,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ion-color-secondary);
}

.popoverUser {
  --offset-y: 10px;
  --min-width: 245px;
}

.popoverCart {
  --offset-y: 10px;
  --min-width: 360px;
}

.container-height {
  height: max(calc((100vh - 108px) - 467px), 700px);
  overflow: auto;
}

ion-modal.modal1[is-open="true"] {
  backdrop-filter: blur(3px);
}

.activateOnScroll.active {
  transform: translateY(0);
  opacity: 1;
}

.mapboxgl-popup-content strong {
  font-weight: 700;
  margin-left: -5px;
}

.mapboxgl-popup-content {
  width: auto;
  padding: 15px 25px !important;
  box-shadow: 0 3px 12px 1px rgb(43 55 72 / 15%) !important;
}

.mapboxgl-popup-content button {
  margin-top: 5px;
  width: 100%;
}
